<template>
  <v-container fluid>
    <v-tabs v-model="selectedTab">
      <v-tab> Сурагчийн шилжилт </v-tab>
      <v-tab> Багшийн шилжилт </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab" style="background-color: white;">
      <v-tab-item> <EsisStudents/></v-tab-item>
      <v-tab-item> <EsisTeacherMovement /></v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
  
  <script>
import EsisTeacherMovement from "@/_esis/EsisTeacherMovement.vue";
import EsisStudents from "@/_esis/EsisStudents.vue";
export default {
  data() {
    return {
      selectedTab: 0,
    };
  },
  components: {
    EsisTeacherMovement,
    EsisStudents,
  },
};
</script>
  