<template>
  <v-container class="my-6" fluid>
    <v-card icon="mdi-clipboard-text" icon-small color="accent">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Шилжилт хөдөлгөөн xийгдсэн багшийн мэдээлэл (2024.01.01-с хойш)
        </h5>
        <p class="text-sm text-body mb-0">
          Энэxүү мэдээллийг <span class="red--text">ESIS</span> системээс шууд
          татаж байна. ESIS системийн хариу үйлдлээс хамааран
          <span class="red--text">2-3 минут</span> болно!
        </p>
        <h3 v-if="errorMessage">{{ errorMessage }}</h3>
      </div>
      <v-row class="mb-6 mt-0 mx-2">
        <v-col
          v-if="movedTeachers"
          cols="6"
          lg="6"
          md="6"
          sm="6"
          class="text-start"
          ><span class="red--text">{{ movedTeachers.length }} </span>
          <small>багшийн бүртгэл олдсон</small>

          <v-text-field
            clearable
            v-model="search"
            append-icon="mdi-magnify"
            label="Xайx"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="6" md="6" sm="6" class="text-end">
          <p class="mb-0">
            <small
              >Мэдээллийг татсан:
              <span class="red--text">{{
                formatDate(this.userData.school._es_movedTeachers_readAt)
              }}</span></small
            >
          </p>
          <v-btn
            data-title2="Багш нарын мэдээллийг ESIS системээс шинэчилж татаx"
            @click="_callMovement"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-info py-3 px-6 ms-3 mr-2"
            >Мэдээлэл татаx</v-btn
          >
          <v-btn
            data-title2="Багш нарын мэдээллийг ESIS системээс шинэчилж татаx"
            @click="showDateSelectDialog = !showDateSelectDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-info py-3 px-6 ms-3 mr-2"
            >Мэдээлэл татаx2</v-btn
          >
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="loading"
        color="red"
        height="6"
        indeterminate
      ></v-progress-linear>
      <v-data-table
        v-if="movedTeachers"
        :headers="headerNames"
        :items="movedTeachers"
        :search="search"
        hide-details
        hide-default-footer
        :items-per-page="-1"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.addedAlready == false"
            class="bg-gradient-success"
            @click="_saveStudent(item)"
            small
            dark
          >
            Багш+
          </v-btn>
          <v-btn
            v-else
            disabled
            text
            @click="_saveStudent(item)"
            small
            class="text-capitalize"
          >
            нэмэгдсэн
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      persistent
      v-model="showDateSelectDialog"
      max-width="400px"
      v-if="showDateSelectDialog"
    >
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDate"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>
        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Цуцлах</v-btn
          >
          <v-btn class="ml-2 bg-gradient-success" dark @click="_selectedDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
// import AuthService from "@/services/auth-service.js";
// const fb = require("@/firebaseConfig.js");
// import Vue from "vue";
import axios from "axios";
import moment from "moment";
const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({
    selectedDate: null,
    showDateSelectDialog: false,
    loading: false,
    search: "",
    headerNames: null,
    movedTeachers: null,
    snackbar: false,
    uName: null,
    pWord: null,
    token: null,
    errorMessage: null,
  }),
  computed: {
    ...sync("*"),
  },
  created() {
    console.log("this.userData.school.ref", this.userData.school.ref);

    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

        if (diffInHours >= 24) {
          this.$swal.fire("Токен даxин аваx шаардлагатай!");
        } else {
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
          console.log(this.tokenData.token);
        }
      }
    });

    this.userData.school.ref
      .collection("moved-teachers")
      .orderBy("FIRST_NAME", "asc")
      .onSnapshot((docs) => {
        this.movedTeachers = [];
        var counter = 0;
        docs.forEach((doc) => {
          counter++;
          let nstud = doc.data();
          nstud.id = doc.id;
          nstud.ref = doc.ref;
          nstud.index = counter;
          console.log(nstud, "st");
          this.movedTeachers.push(nstud);
        });
      });
  },
  mounted() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "FIRST_NAME",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "LAST_NAME",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "STUDENT_GROUP_NAME", //className
      },

      {
        text: "ACTION_NAME",
        align: "start",
        sortable: false,
        value: "ACTION_NAME",
      },
      // {
      //   text: "ACTION_ID",
      //   align: "start",
      //   sortable: true,
      //   value: "ACTION_ID",
      // },

      {
        text: "APPROVAL_STATUS",
        align: "start",
        sortable: false,
        value: "APPROVAL_STATUS",
      },
      {
        text: "PROGRAM_STATUS",
        align: "start",
        sortable: false,
        value: "PROGRAM_STATUS",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];
  },
  methods: {
    _selectedDate() {
      console.log(this.selectedDate);
      if (this.selectedDate) {
        this.showDateSelectDialog = !this.showDateSelectDialog;
        this._callMovement(this.selectedDate);
      } else {
        this.$swal.fire("Анхааруулга", "Он сар өдөр сонгоно уу!");
      }
    },
    _callMovement(datee) {
      var callUrlStr = datee
        ? "https://hub.esis.edu.mn/svc/api/hub/teacher/movements/" + datee
        : "2024-08-01";

      console.log(callUrlStr, "callUrlStr");
      if (
        this.userData.school._esisUserName &&
        this.userData.school._esisPword
      ) {
        this.$swal({
          title: datee + ": ESIS системээс мэдээллийг шинэчлэн татаx уу?",
          text: "Шилжилт хөдөлгөөнтэй мэдээллийг татаж, системд xадгална!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.uName = this.userData.school._esisUserName;
            this.pWord = this.userData.school._esisPword;
            this.loading = true;

            axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                {
                  url: callUrlStr,
                  token: this.tokenData.token,
                }
              )
              .then(async (res) => {
                this.loading = false;
                console.log(res.data, "response");
                if (res.data["SUCCESS_CODE"] == 200) {
                  console.log(res.data["RESULT"], "teachers");
                  this.movedTeachers = [];
                  this.userData.school.ref.update({
                    _es_movedTeachers_readAt: new Date(),
                  });
                  var batch = fb.db.batch();
                  res.data["RESULT"].forEach(async (teacher) => {
                    await this.userData.school.ref
                      .collection("teachers")
                      .doc(teacher.PERSON_ID.toString())
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          teacher.addedAlready = true;
                        } else teacher.addedAlready = false;
                        batch.set(
                          this.userData.school.ref
                            .collection("moved-teachers")
                            .doc(teacher.PERSON_ID.toString()),
                          teacher,
                          { merge: true }
                        );
                      });
                    await batch.commit();
                  });
                }
              });
          }
        });
      }
    },
    _saveStudent(item) {
      this.$swal({
        title: item.LAST_NAME + " багшийг нэмэх үү?",
        text: "Шинээр системд xадгална!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          // var gg = await this.userData.school.ref
          //   .collection("departments-" + this.userData.school.currentYear)
          //   .doc("department-" + item.ACADEMIC_LEVEL)
          //   .collection("programs")
          //   .doc(item.STUDENT_GROUP_ID.toString())
          //   .get();
          // if (gg.exists) {
          //   item["classGroup-" + this.userData.school.currentYear] = gg.ref;
          //   item["classGroupName-" + this.userData.school.currentYear] =
          //     item.STUDENT_GROUP_NAME ? item.STUDENT_GROUP_NAME : null;
          //   item.role = "teacher";
          //   item.roleName = "Сурагч";
          //   item.readfrom_esis = true;
          //   item.email = item.EMAIL ? item.EMAIL : null;
          //   item.readfrom_esis = true;
          //   item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
          //   item.classGroupName = item.STUDENT_GROUP_NAME;
          //   item.deleted = false;
          //   item.created = new Date();
          //   item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
          //   item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;
          //   this.userData.school.ref
          //     .collection("students-" + this.userData.school.currentYear)
          //     .doc(item.PERSON_ID.toString())
          //     .set(item, { merge: true })
          //     .then(() => {
          //       console.log(item.ref.path);
          //       item.ref.delete().then(() => {
          //         this.$swal(
          //           item.firstName +
          //             " сурагч " +
          //             item.STUDENT_GROUP_NAME +
          //             " бүлэгт амжилттай нэмэгдсэн"
          //         );
          //       });
          //     });
          // }
        }
      });
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "татаагүй";
    },
  },
};
</script>
